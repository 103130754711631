<template>
  <div class="popup-layer-wrap previous-notice" :class="{ on, ing }">
    <div class="__layer">
      <p class="tit">이전 공고 불러오기</p>
      <ul class="list">
        <li
          class="previous-notice-item"
          v-for="(recruit, idx) in recruitsList"
          :key="`rc_${idx}`"
        >
          <span class="date">{{ recruit.regDt }}</span>
          <span
            class="status"
            :class="{
              ing: recruit.documentStatus === 'RECRUITING', //진행중, 없으면 마감, 작성 중 추가될 예정
            }"
            >{{ RECRUITING_STATUS[recruit.documentStatus] }}</span
          >
          <span class="text">{{ recruit.jobPostTitle }}</span>
          <div class="btn-box">
            <!-- <button
              class="btn"
              type="button"
              @click="editRecruit(recruit.documentNo)"
            >
              수정
            </button> -->
            <button
              class="btn"
              type="button"
              v-if="recruit.documentStatus === 'CONTEMPORARY'"
              @click="deleteRecruit(recruit.documentNo)"
            >
              삭제
            </button>
            <button
              class="btn"
              type="button"
              @click="copyRecruit(recruit.documentNo)"
            >
              <span v-if="recruit.documentStatus === 'CONTEMPORARY'">
                불러오기
              </span>
              <span v-else> 복사</span>
            </button>
          </div>
        </li>
      </ul>
      <button class="btn closed" type="button" @click="close">닫기</button>
    </div>
  </div>
</template>

<script>
import popupMixin from 'Mixins/popup'
import { getPreviousRecruitsList, deletePreviousRecruit } from 'Api/modules'
import { mapGetters } from 'vuex'
import { RECRUITING_STATUS } from 'Configs/registJob'

export default {
  mixins: [popupMixin],
  data() {
    return {
      recruitsList: [],
      RECRUITING_STATUS: Object.freeze(RECRUITING_STATUS),
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  watch: {
    async visible(newVal) {
      if (newVal) {
        this.recruitsList = await getPreviousRecruitsList({
          userId: this.account.userId,
        })
      }
    },
  },
  methods: {
    editRecruit(documentNo) {
      this.$emit('editRecruit', documentNo)
      this.close()
    },
    copyRecruit(documentNo) {
      this.$emit('copyRecruit', documentNo)
      this.close()
    },
    async deleteRecruit(documentNo) {
      // if (documentStatus !== 'CONTEMPORARY') {
      //   this.$toasted.error('작성완료된 구인공고는 삭제할 수 없습니다.')
      //   return
      // }
      await deletePreviousRecruit({
        documentNo: documentNo,
      })
      this.recruitsList = await getPreviousRecruitsList({
        userId: this.account.userId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .popup-layer-wrap .__layer .tit {
    padding: 0 0 22px 0;
    height: unset;
  }
}
@media (max-width: 1023px) {
  .previous-notice-item {
    .text {
      min-width: 100%;
    }
  }
}
</style>
